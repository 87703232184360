import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex_spacebetween_centered column fp_container text-center" }
const _hoisted_2 = { class: "flex_align_centered column mb-5" }
const _hoisted_3 = { class: "d-block mt-2 fev_text5 fw_700" }
const _hoisted_4 = { class: "flex_centered column mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_form_field = _resolveComponent("form-field")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { class: "mb-4" }),
      _createVNode(_component_localized_text, {
        localizedKey: "app_ABreveRiceveraiIlCodiceDiConfermaAQuestoNumero",
        text: "A breve riceverai il codice di conferma a questo numero",
        class: "fev_text5 fw_700"
      }),
      _createVNode("span", _hoisted_3, _toDisplayString(_ctx.phoneNumber.prefix) + " " + _toDisplayString(_ctx.phoneNumber.number), 1),
      _createVNode(_component_localized_text, {
        localizedKey: "app_InviaDiNuovo",
        text: "Invia di nuovo",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetConfirmationCode())),
        class: "fev_text5 fw_700 text_underlined bg_brand bg_on_text mt-3"
      })
    ]),
    _createVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["prevent","stop"]))
    }, [
      _createVNode("div", null, [
        _createVNode(_component_form_field, {
          type: "CodeConfirmation",
          modelValue: _ctx.code,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.code = $event)),
          codeLength: _ctx.codeLength,
          required: true
        }, null, 8, ["modelValue", "codeLength"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("button", {
          type: "submit",
          class: "fev_button bg_brand mb-3",
          disabled: _ctx.isDisabled
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_Conferma",
            text: "Conferma"
          })
        ], 8, ["disabled"])
      ])
    ], 32)
  ]))
}