
import { Options, Vue } from 'vue-class-component';
import askForPromoCode from './steps/askForPromoCode.vue';
import insertPromoCode from './steps/insertPromoCode.vue';
import { AppUserClient } from '@/services/Services';
import promoErrorModal from './steps/promoErrorModal.vue';
import promoSuccessModal from './steps/promoSuccessModal.vue';

@Options({
    components: {
        askForPromoCode,
        insertPromoCode
    }
})
export default class insertPromoCodeModal extends Vue {

    step: number = 0;
    referralCode: string = "";

    created(){
    }

    back(){
        if(this.step > 0)
            this.step --;
    }

    next(referralCode: string = ""){
        if(this.step < 1)
            this.step ++;
        else 
            this.save(referralCode);
    }

    save(referralCode: string){
        referralCode = referralCode.trim();
        
        if(!referralCode)
            return;

        AppUserClient.insertPromoCode(referralCode)
        .then( x => {
            this.$opModal.show(promoSuccessModal, {
                
            })
        }).catch( ex => {
            this.$opModal.show(promoErrorModal, {
            
            })
        })
    }

}
