
import WithModel from '@/components/standard/formFieldPlugin/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class insertPromoCode extends mixins(WithModel) {


    get isDisabled() {
        return !this.localValue.trim();
    }

    next(){
        this.$emit("next", this.localValue);
    }

}
