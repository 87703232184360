
import { AppUserClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import store from '@/store';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import InfoModal from '@/components/modals/infoModal.vue';
import insertPromoCodeModal from '../modals/inserPromoModals/insertPromoCodeModal.vue';
import { CommonServices } from '@/services/CommonServices';

@Options({
    components: {
    }
})
export default class InsertCode extends Vue {

    phoneNumber: VM.PhoneNumber = new VM.PhoneNumber();
    username: string = "";
    userIdentifier: string = "";
    code: string = "";
    codeLength: number = 6;
    userPosition: OM.UpdateUserPositionVm = new OM.UpdateUserPositionVm();
    promoCodeAlreadyInserted: boolean = false;

    created() {
        if(store.state.registeredPhoneNumber)
            this.phoneNumber = store.state.registeredPhoneNumber;

        this.userIdentifier = store.state.loggedUser.identifier;
        this.username = store.state.loggedUser.username;
        var position = StorageServices.getCurrentPosition();
        this.userPosition.latitude = position.latitude;
        this.userPosition.longitude = position.longitude;

        AppUserClient.promoCodeAlreadyInserted(this.userIdentifier, false)
        .then( x => {
            this.promoCodeAlreadyInserted = x;
        })
    }

    get isDisabled(){
        var tempCode = this.code.replaceAll(" ", "");

        return tempCode.length != this.codeLength;
    }

    resetConfirmationCode(){
        AppUserClient.resetConfirmationCode(this.username)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("app_TiEStatoInviatoUnNuovoMessaggio", "Ti è stato inviato un nuovo messaggio"),
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
        .catch( ex => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    next(){
        CommonServices.showSpinner();
        AppUserClient.confirm(store.state.loggedUser.identifier, this.code, store.state.loggedUser.preferredCulture)
        .then( x => {

            StorageServices.setNewTokens(x.token, x.refreshToken);

            if(!this.promoCodeAlreadyInserted){
                CommonServices.hideSpinner();
                this.insertPromoModal();
            }
            else 
                this.proceedToLoginCallback();
        })
        .catch( ex => {
            CommonServices.hideSpinner();
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    insertPromoModal(){
        this.$opModal.show(insertPromoCodeModal, {

        }, () => {
            this.proceedToLoginCallback();
        })
    }

    proceedToLoginCallback(){
        var token = store.state.loggedUser.token;
        var refreshToken = store.state.loggedUser.refreshToken;

        AppUserClient.updateUserPosition(this.userPosition)
        .then( y => {
            if(this.promoCodeAlreadyInserted){
                CommonServices.hideSpinner();
            }

            LoginServices.LoginCallback(token, refreshToken);
        })
    }

}
